
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  PropType,
  watch,
} from "vue";

export interface Data {
  pagination: Pagination;
  isLoaded: boolean;
}

export interface Pagination {
  currentPage?: number;
  totalPages?: number;
  totalCount?: number;
  pageSize?: number;
}

export default defineComponent({
  name: "Pagination",
  props: {
    pagination: Object as PropType<Pagination>,
  },
  components: {},
  setup(props, ctx) {
    let data = reactive<Data>({
      pagination: {
        currentPage: 0,
        totalPages: 0,
        totalCount: 0,
        pageSize: 0,
      },
      isLoaded: false,
    });

    watch(
      () => props.pagination,
      (currentValue, oldValue) => {
        data.pagination.currentPage = props.pagination?.totalCount == 0 ? 0 : props.pagination?.currentPage;
        data.pagination.totalPages = props.pagination?.totalPages;
        data.pagination.totalCount = props.pagination?.totalCount;
        data.pagination.pageSize = props.pagination?.pageSize;
      },
      { deep: true }
    );

    onMounted(async () => {
      data.pagination.currentPage = props.pagination?.totalCount == 0 ? 0 : props.pagination?.currentPage;
      data.pagination.totalPages = props.pagination?.totalPages;
      data.pagination.totalCount = props.pagination?.totalCount;
      data.pagination.pageSize = props.pagination?.pageSize;
      data.isLoaded = true;
    });

    async function changePage(index) {
      if (props.pagination) {
        if (props.pagination.currentPage == 1 && index < 0) return;
        if (
          props.pagination.currentPage == props.pagination.totalPages &&
          index > 0
        )
          return;
        ctx.emit("pageChanged", props.pagination.currentPage + index);
      }
    }

    async function selectPage(index) {
      if (
        data.pagination &&
        data.pagination.currentPage != undefined &&
        data.pagination.totalPages
      ) {
        if (data.pagination.currentPage > data.pagination.totalPages) {
          data.pagination.currentPage = data.pagination.totalPages;
        }
        if (data.pagination.currentPage < 1) {
          data.pagination.currentPage = 1;
        }
        ctx.emit("pageChanged", data.pagination.currentPage);
      }
    }

    async function paginationChanged() {
      ctx.emit("pageSizeChanged", data.pagination.pageSize);
    }

    function isNumber(evt) {
      const keysAllowed: string[] = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ];
      const keyPressed: string = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    }

    return {
      selectPage,
      changePage,
      paginationChanged,
      isNumber,
      data,
    };
  },
});
