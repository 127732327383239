import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/statements";

export async function searchStatements(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addStatement(statement) {
  const headers = { "Content-Type": "multipart/form-data" };
  return await ApiService.postForm(url, statement, { headers: headers }).catch(
    ({ response }) => {
      processErrors(response);
    }
  );
}

export async function download(statement) {
  return await ApiService.query(url + "/getFile?id=" + statement.id, {
    responseType: "blob",
  }).then((res) => {
    const fileURL = window.URL.createObjectURL(new Blob([res.data]));
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    //TODO temp
    fileLink.setAttribute("download", statement.statementId + ".pdf");
    document.body.appendChild(fileLink);

    fileLink.click();
  });
}
