import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { string } from "yup";

export interface FilterInfo {
  name: string;
  isFilter?: boolean;
  key: string;
  formType?: string;
  keyword: string | null;
  IsAdvancedOrderBy?: boolean | null;
  orderProperty?: string | null;
}

export default function () {
  const orderList = ref<FilterInfo[]>([
    {
      name: "Entry Date",
      key: "createdOn",
      isFilter: true,
      keyword: null,
      formType: "isDate",
      orderProperty: "createdOn",
    },
  ]);
  const route = useRoute();
  const router = useRouter();
  function getFiltersFromUrl() {
    const filters = route.query.filters as string;
    if (filters) {
      const filtersArray = JSON.parse(filters);
      filtersArray.forEach((filter) => {
        const header = getFilterItem(filter.k) as any;
        if (header) {
          (header.keyword as any) = filter.v;
        }
      });
    }
  }

  function putFiltersToUrl() {
    if (orderList.value) {
      const currentFilters = orderList.value.filter((f) => {
        return f.keyword != undefined && f.keyword !== "" && f.keyword != null;
      });
      if (currentFilters.length > 0) {
        const currentRounte = route.fullPath;
        const filtersList = currentFilters.map((item) => {
          return { k: item.key, v: item.keyword };
        });
        router.replace({
          path: currentRounte,
          query: { filters: JSON.stringify(filtersList) },
        });
      } else {
        router.replace({
          path: route.path,
        });
      }
    }
  }

  function getFilterItem(key) {
    if (orderList.value) {
      const header = orderList.value.find((item: any) => {
        return item.key == key;
      });
      return header;
    }
  }

  function cleanHeaderFilter(headerKey) {
    (getFilterItem(headerKey) as any).keyword = null;
  }

  function getFilter(key) {
    const header = orderList.value.find((item) => {
      return item.key == key;
    });
    if (
      header &&
      header.keyword != null &&
      header.keyword != undefined &&
      (typeof header.keyword != "string" || header.keyword != "")
    ) {
      return header.keyword;
    }
    return null;
  }

  return {
    getFiltersFromUrl,
    orderList,
    putFiltersToUrl,
    cleanHeaderFilter,
    getFilter,
  };
}
